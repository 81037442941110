<template>
    <div class="packages" id="packages">
      <div class="container">
        <div class="row">
          <div class="col pt-1">
            <h1 class="text-uppercase fw-bold optimus-orange-text" style="text-shadow: 2px 2px 2px #444444 ">Select your suitable package</h1>
          </div>
        </div>
        <RegularPackage/>
      </div>
    </div>
</template>

<script>
import RegularPackage from "@/components/packages/RegularPackage";
export default {
  components:{
    RegularPackage
  },
data() {
    return {
       title:'Packages', 
    }
},
mounted() {
    document.title = this.title;
},
}
</script>

<style scoped>
    
</style>