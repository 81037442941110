<template>
  <div class="regular-package">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 mb-3 package" v-for="(item,key ) in packages" :key="key">
        <div class="card p-2">
          <div class="card-header bg-success"><h4 class="text-light fw-bold mb-0">{{ item.name }}</h4></div>
          <div class="card-body p-0 table-responsive">
            <table class="table table-striped mb-0">
              <tbody>
              <tr class=""><td class="fw-bold">{{item.bill}}/month</td></tr>
              <tr class=""><td>{{ item.speed }} 24 hours speed</td></tr>
              <tr class=""><td>{{ item.yt }} Youtube</td></tr>
              <tr class=""><td>{{ item.fb }} Facebook</td></tr>
              <tr class=""><td>{{ item.ftp }} FTP</td></tr>
              <tr class=""><td>{{  item.bdix }} BDIX</td></tr>
              <tr class=""><td>{{ item.movie }} Movies</td></tr>
              <tr class=""><td>{{ item.game }} Games</td></tr>
              <tr class=""><td>Live TV</td></tr>
              <tr class=""><td>24/7 Online Support</td></tr>
              <tr class=""><td>{{ item.ratio }}</td></tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer fw-bold ps-2 pe-2"><button class="btn btn-info btn-sm form-control fw-bold">ORDER NOW</button></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegularPackage",
  data(){
    return {
      packages: [
        {name:'Silver', bill:'Tk.700', speed: '20 Mbps', yt: 'Buffer Less', fb:'Buffer Less',ftp:'Unlimited', bdix:'Unlimited', movie:'Unlimited',game:'Unlimited', ratio:'1:8 Connection Ratio'},
        {name:'Gold', bill:'Tk.800', speed: '30 Mbps', yt: 'Buffer Less', fb:'Buffer Less',ftp:'Unlimited', bdix:'Unlimited', movie:'Unlimited',game:'Unlimited', ratio:'1:8 Connection Ratio'},
        {name:'Freelancer', bill:'Tk.1000', speed: '40 Mbps', yt: 'Buffer Less', fb:'Buffer Less',ftp:'Unlimited', bdix:'Unlimited', movie:'Unlimited',game:'Unlimited', ratio:'1:8 Connection Ratio'},
        {name:'Gaming', bill:'Tk.1200', speed: '50 Mbps', yt: 'Buffer Less', fb:'Buffer Less',ftp:'Unlimited', bdix:'Unlimited', movie:'Unlimited',game:'Unlimited', ratio:'1:8 Connection Ratio'},
        {name:'Diamond', bill:'Tk.1500', speed: '60 Mbps', yt: 'Buffer Less', fb:'Buffer Less',ftp:'Unlimited', bdix:'Unlimited', movie:'Unlimited',game:'Unlimited', ratio:'1:8 Connection Ratio'},
        {name:'Sky', bill:'Tk.2000', speed: '80 Mbps', yt: 'Buffer Less', fb:'Buffer Less',ftp:'Unlimited', bdix:'Unlimited', movie:'Unlimited',game:'Unlimited', ratio:'1:8 Connection Ratio'},
      ]
    }
  },
}
</script>

<style scoped>
.package:hover{
  transform: scale(1.1);
  transition: 0.3s;
  z-index: 10;
}
</style>
